import {
    CLICK,
    SCROLL_BUTTON,
    TEXT_CONTENT
} from "../constants";

export class ScrollButton extends HTMLElement {
    constructor() {
        super();
    }

    addListener() {
        this.addEventListener(CLICK, this.handleClick);
        window.addEventListener('scroll', this.handleScroll);
    }

    removeListener() {
        this.removeEventListener(CLICK, this.handleClick);
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    handleScroll = () => {
        if (window.scrollY < this.parentElement.offsetTop || this.parentElement.offsetTop === 0) {
            this.classList.add('hide');
        } else {
            this.classList.remove('hide');
        }
    };

    async connectedCallback() {
        const textContent = this.getAttribute(TEXT_CONTENT);

        if (textContent) this.textContent = textContent;

        this.className = 'scroll-button hide';

        this.addListener();
    }

    disconnectedCallback() {
        this.removeListener();
    }
}

if (!customElements.get(SCROLL_BUTTON)) {
    customElements.define(SCROLL_BUTTON, ScrollButton);
}
