import { renderToString } from "jsx-async-runtime";
import { SPINNER } from "../constants";

export type SpinnerProps = {
    color: string;
    cover: boolean;
    emptyColor: string;
    fixed: boolean;
    label: string;
    size: 'small' | 'medium' | 'large';
    speed: string;
    thickness: string;
};

const defaultSpinnerProps: SpinnerProps = {
    color: "currentColor",
    cover: false,
    emptyColor: "transparent",
    fixed: false,
    label: "",
    size: "medium",
    speed: "0.75s",
    thickness: "2px",
};

export class Spinner extends HTMLElement {

    private _spinnerProps: SpinnerProps;
    

    get spinnerProps(): SpinnerProps {
        return this._spinnerProps;
    }

    set spinnerProps(value: SpinnerProps) {
        this._spinnerProps = { ...defaultSpinnerProps, ...value};
    }

    constructor() {
        super();
    }

    async connectedCallback() {
        let additionalClass = "";
        if(this.spinnerProps.cover)
            additionalClass = "cover-fixed-styles";
        if(this.spinnerProps.fixed)
            additionalClass = "cover-fixed-styles fixed";
        const html = (
            <div class={`spinner-wrapper ${additionalClass}`} aria-live="assertive" role="status" tabindex="0">
                <div class={`spinner ${additionalClass} ${this.spinnerProps.size}`} style={`--spinner-thickness: ${this.spinnerProps.thickness}; --spinner-emptyColor:${this.spinnerProps.emptyColor}; --spinner-speed:${this.spinnerProps.speed}; --spinner-color:${this.spinnerProps.color}`}>
                    <span class="spinner-label">{this.spinnerProps.label}</span>
                </div>
            </div>
        );

        this.innerHTML = await renderToString(html);
    }
}

if (!customElements.get(SPINNER)) {
    customElements.define(SPINNER, Spinner);
}
