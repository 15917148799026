import ApiError from "./apiError";

export type FatalError = {
  clientErrorDescription: string;
} & ApiError;

export const buildFatalError = (
  error: ApiError,
  clientErrorDescription: string
) => ({ ...error, clientErrorDescription });
