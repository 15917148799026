import { renderToString } from "jsx-async-runtime";
import PaymentSettings from "../model/payment/PaymentSettings";
import {SUBSCRIPTION_CONFIRMATION} from "../constants/subscription-confirmation";
import {SUBSCRIPTION_CONFIRMATION_STATEMENTS_AND_CHECKBOX} from "../constants";
import { AdditionalValidationElement } from "../model/validation/AdditionalValidationElement";

export class SubscriptionConfirmation extends HTMLElement implements AdditionalValidationElement {
  private _formSettings: PaymentSettings;

  constructor() {
    super();
  }
  showElement(): void {
    this.style.display = "";
  }
  hideElement(): void {
    this.style.display = "none";
  }
  validateElement(): boolean {
    const checkBox = this.querySelector("#subscription-confirmation-checkbox") as HTMLInputElement;
      return checkBox.checked;
  }

  get formSettings(): PaymentSettings {
    return this._formSettings;
  }

  set formSettings(value: PaymentSettings) {
    this._formSettings = value;
  }

  addCleanupListener() {
    document.getElementById(SUBSCRIPTION_CONFIRMATION).addEventListener('click',
      this.clickHandler());
  }

  removeCleanupListener() {
    document.getElementById(SUBSCRIPTION_CONFIRMATION).removeEventListener('click',
      this.clickHandler());
  }

  clickHandler = () => () => {
    let host = document.getElementById(SUBSCRIPTION_CONFIRMATION_STATEMENTS_AND_CHECKBOX);
    host.className = "";
  }

  async connectedCallback() {
    const formSettingsData = this.formSettings;

    const html = (
      <div id={SUBSCRIPTION_CONFIRMATION_STATEMENTS_AND_CHECKBOX}>
        <ul data-testId="SubscriptionConfirmationInfo">
          <li class="subscription-confirmation-statement">{formSettingsData.resources.subscriptionPayment.understandStatement}</li>
          <li class="subscription-confirmation-statement">{formSettingsData.resources.subscriptionPayment.agreeStatement}</li>
        </ul>
        <div id={SUBSCRIPTION_CONFIRMATION} class="subscription-confirmation">
          <label class="subscription-confirmation-checkbox-container" data-testid="SubscriptionConfirmationLabel">
            {formSettingsData.resources.subscriptionPayment.checkboxLabel}
            <span class="subscription-confirmation-required">*</span>
            <span>
              <input id="subscription-confirmation-checkbox" type="checkbox" name="SubscriptionConfirmation"/>
              <span id="subscription-confirmation-checkmark" data-testid="SubscriptionConfirmationCheckbox" class="subscription-confirmation-checkmark"></span>
            </span>
          </label>
          <p id="subscription-confirmation-checkbox-error" class="subscription-confirmation-error" hidden="hidden">
            {formSettingsData.resources.subscriptionPayment.validationErrorMessage}
          </p>
        </div>
      </div>
    );

    this.innerHTML = await renderToString(html);
    this.addCleanupListener();
  }

  async disconnectedCallback() {
    this.removeCleanupListener();
  }
}

if (!customElements.get(SUBSCRIPTION_CONFIRMATION)) {
  customElements.define(SUBSCRIPTION_CONFIRMATION, SubscriptionConfirmation);
}
