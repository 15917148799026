export const ADYEN_DROPIN_CONTAINER = "adyen-dropin-container";
export const PAYMENTS_SUMMARY = "payments-summary";
export const PAYMENTS_WEB_COMPONENT = "payments-webcomponent";
export const PAYMENTS_PAID_CLASS_WEB_COMPONENT = "payments-paidclass-webcomponent";
export const FORM_ERROR_CONTAINER = "form-error-container";
export const SPINNER = "pure-spinner";
export const LEGAL_TERMS = "legal-terms";
export const LEGAL_TERMS_FOR_PAID_CLASS = "legal-terms-for-paid-class";
export const FORM_LOADING_SPINNER = "form-loading-spinner";
export const HOST_CONTAINER = "pure-container";
export const BUNDLE_LOADING_SPINNER = "bundle-loading-spinner";
export const SUBSCRIPTION_CONFIRMATION_STATEMENTS_AND_CHECKBOX = "subscription-confirmation-statements-and-checkbox";
export const TERMS_AND_CONDITIONS = "terms-and-conditions";
export const SCROLL_BUTTON = "scroll-button";
