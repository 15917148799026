/* Other constants */
export const TERMS_AND_CONDITIONS_HEADER = "terms-and-conditions-header";

export const TERMS_AND_CONDITIONS_PANEL = "terms-and-conditions-panel"

/* Terms and conditions taken from https://www.puregym.com/membership-terms-conditions/ */
export const termsAndConditionsTitle = "Terms & Conditions";

export const premiumClassesTerms = {
  "header": "Premium Classes",
  "openingStatement": "As a PureGym member, you have the opportunity to purchase premium group exercise classes at selected centres. You can see the specific gyms in the App.",
  "ageRestriction": "To be able to buy and book group exercise classes, members must be over 18 years old.",
  "mainTerms": [
    "All chargeable classes must be paid for at the time of booking. If cancelled 24 hours before class starts credit will be added to your account.",
    "Members must arrive promptly for class start times; late arrival may result in being refused entry to the class.",
    "Members are recommended to book in advance to ensure that a class has space for them.",
    "Bookings for chargeable classes are for over 18 only.",
    "If you do not turn up to a class booking a cancellation policy will apply."
  ],
  "paymentTermsTitle": "Payment Terms:",
  "paymentTerms": [
    "All bookings for chargeable group exercise classes must be paid for in advance.",
    "Payments can be made via credit card/debit card/online payment portal.",
    "No spot in the class is confirmed until full payment has been received."
  ],
  "nonRefundablePolicyTitle": "Non-Refundable Policy:",
  "nonRefundablePolicyTerms": [
    "All payments for chargeable group exercise classes are non-refundable.",
    "Once a payment is confirmed, refunds will not be issued for any reason, including but not limited to: change of mind; inability to attend due to personal circumstances; scheduling conflicts.",
    "This policy applies regardless of the amount of notice given prior to the class date."
  ],
  "cancellationPolicyTitle": "Cancellation Policy:",
  "ifYouCancelTitle": "If you cancel:",
  "ifYouCancelTerms": [
    "A booked class before 24 hours of the class, we will add credit back to your account to book another class.",
    "A booked class within 24 hours of the class the booking will be considered used, and no refunds, rescheduling, or credits will be issued."
  ],
  "ifPureGymCancelsTitle": "If PureGym cancel:",
  "ifPureGymCancelsTerms": [
    "A specific class, you will be given the option to be transferred to another class of the same value or receive a full credit to your account towards future bookings.",
    "All classes in the gym you have bought classes to, a refund will be processed within 7 working days to you."
  ],
  "bookingRightsTitle": "Booking Rights:",
  "bookingRightsTerms": [
    "Members must arrive promptly for class start times, late arrival may result in being refused entry to the class.",
    "Members are recommended to book in advance to ensure that a class has space for them.",
    "Bookings are non-transferable to another person or account.",
    "PureGym reserves the right to modify class schedules, including instructors and formats, without notice. These changes will not entitle attendees to a refund",
    "The Group Exercise amount is valid for a 2 months period, and will not be refunded it not used."
  ],
};
