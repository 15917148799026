import { renderToString } from "jsx-async-runtime";
import PaymentSettings from "../model/payment/PaymentSettings";
import { CLICK, LEGAL_TERMS_FOR_PAID_CLASS, TERMS_AND_CONDITIONS } from "../constants";
import { TERMS_AND_CONDITIONS_HEADER } from "../constants/terms-and-conditions";
import { CHECKBOX_LABEL, HYPERLINK_TEXT, VIEW_LEGAL_TERMS } from "../constants/legal-terms-for-paid-class";
import { TermsAndConditions } from "./terms-and-conditions";
import { AdditionalValidationElement } from "../model/validation/AdditionalValidationElement";
export class LegalTermsForPaidClass extends HTMLElement implements AdditionalValidationElement {
  private _formSettings: PaymentSettings;

  constructor() {
    super();
  }
  showElement(): void {
    this.style.display = "";
    const terms = document.getElementById(TERMS_AND_CONDITIONS) as TermsAndConditions;
    if (terms)
      terms.style.display = "";
  }
  hideElement(): void {
    this.style.display = "none";
    const terms = document.getElementById(TERMS_AND_CONDITIONS) as TermsAndConditions;
    if (terms)
      terms.style.display = "none";
  }
  validateElement(): boolean {
    const checkBox = this.querySelector("#product-legal-terms") as HTMLInputElement;
    return checkBox.checked;
  }

  get formSettings(): PaymentSettings {
    return this._formSettings;
  }

  set formSettings(value: PaymentSettings) {
    this._formSettings = value;
  }

  addCleanupListener() {
    document.getElementById(LEGAL_TERMS_FOR_PAID_CLASS).addEventListener(CLICK,
      this.clickHandler());

    document.getElementById(VIEW_LEGAL_TERMS).addEventListener(CLICK,
      this.toggleTermsAndConditions);
  }

  removeCleanupListener() {
    document.getElementById(LEGAL_TERMS_FOR_PAID_CLASS).removeEventListener(CLICK,
      this.clickHandler());

    document.getElementById(VIEW_LEGAL_TERMS).removeEventListener(CLICK,
      this.toggleTermsAndConditions);
  }

  clickHandler = () => () => {
    let host = document.getElementById(LEGAL_TERMS_FOR_PAID_CLASS);
    host.className = "";
  }

  scrollToTermsAndConditions = () => {
    const terms = document.getElementById(TERMS_AND_CONDITIONS_HEADER) as TermsAndConditions;

    if (terms) {
      if (terms.ariaExpanded === "false")
        terms.click();
      const offsetTop = terms.offsetTop;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  }

  toggleTermsAndConditions = (event: Event) => {
    event.preventDefault();

    this.scrollToTermsAndConditions();
  };

  async connectedCallback() {
    const formSettingsData = this.formSettings;

    const html = (
      <div id={LEGAL_TERMS_FOR_PAID_CLASS}>
        <label class="legal-terms-checkbox-container" data-testid="ProductLegalTermsLabel">{CHECKBOX_LABEL}
          <span class="legal-terms-required">*</span>
          <input id="product-legal-terms" type="checkbox" name="ProductLegalTerms" />
          <span class="legal-terms-link">
            <a
              id={VIEW_LEGAL_TERMS}
              data-testid="ProductLegalTermsLink"
              href="#"
            >
              {HYPERLINK_TEXT}
            </a>
          </span>
          <span id="legal-terms-checkmark" data-testid="ProductLegalTermsCheckbox"
            class="legal-terms-checkmark"></span>
        </label>
        <p data-testid="ProductLegalTermsError" id="product-legal-terms-error" class="legal-terms-error"
          hidden="hidden">
          {formSettingsData.resources.legalTerms.notAgreedError}
        </p>
      </div>
    );

    this.innerHTML = await renderToString(html);
    this.addCleanupListener();
  }

  async disconnectedCallback() {
    this.removeCleanupListener();
  }
}

if (!customElements.get(LEGAL_TERMS_FOR_PAID_CLASS)) {
  customElements.define(LEGAL_TERMS_FOR_PAID_CLASS, LegalTermsForPaidClass);
}
