import { FormSettings } from "../model/common/FormSettings";
import { FormSettingsType } from "../model/common/FormSettingsType";
import AdyenProviderSettings from "../model/common/providers/adyen/AdyenProviderSettings";
import {
  AdyenDropinContainer,
  AdyenDropinContainerConfiguration,
} from "./adyen-dropin-container";
import { PaymentsSummary } from "./payments-summary";
import { renderToString } from "jsx-async-runtime";
import {
  API_BASE_ADDRESS,
  BRAND_HEADER,
  CULTURE_HEADER,
  IS_TEST_ENVIRONMENT,
  LIVE,
  PG_BRAND,
  PG_CULTURE,
  SESSION_ID,
  TEST,
  ADYEN_DROPIN_CONTAINER,
  HOST_CONTAINER,
  PAYMENTS_SUMMARY,
  OMIT,
  INCLUDE,
  SPINNER,
  FORM_LOADING_SPINNER,
  BUNDLE_LOADING_SPINNER,
  LEGAL_TERMS_FOR_PAID_CLASS,
  PAYMENTS_PAID_CLASS_WEB_COMPONENT,
  TERMS_AND_CONDITIONS,
} from "../constants";
import HttpRequest from "../utils/HttpRequest";
import { PaymentProviderType } from "../model/common/PaymentProviderType";
import { Spinner, SpinnerProps } from "./spinner";
import { LegalTerms } from "./legal-terms";
import { AdditionalValidation } from "../model/validation/AdditionalValidation";
import { AdditionalValidationType } from "../model/validation/AdditionalValidationType";
import { LEGAL_TERMS_CHECKBOX } from "../constants/legal-terms";
import { TermsAndConditions } from "./terms-and-conditions";
import { LegalTermsForPaidClass } from "./legal-terms-for-paid-class";

class PaymentsPaidClassWebComponent extends HTMLElement {
  static observedAttributes = [SESSION_ID];
  apiBaseUrl: string;
  sessionId: string;
  isTestEnvironment: boolean;
  brand: string;
  culture: string;
  hostContainerId: string;
  firstRender: boolean = true;
  constructor() {
    super();
  }

  async getFormSettings(): Promise<FormSettings> {
    const headers = new Headers();
    headers.append(BRAND_HEADER, this.brand);
    headers.append(CULTURE_HEADER, this.culture);

    let credentials = OMIT as RequestCredentials;
    if (this.isTestEnvironment) {
      credentials = INCLUDE as RequestCredentials;
    }
    const url = `${this.apiBaseUrl}/formsettings/${this.sessionId}`;
    const response = await HttpRequest.get({
      url,
      credentials,
      brand: this.brand,
      culture: this.culture,
    });
    return response.data;
  }

  toggleTermsAndConditions() {
    const termsAndConditions = this.querySelector(TERMS_AND_CONDITIONS) as TermsAndConditions;

    if (termsAndConditions) {
      termsAndConditions.togglePanel();
    }
  };


  changeDisplay(event: CustomEvent) {
    const {detail: show} = event;

    const elements = [
      this.querySelector(TERMS_AND_CONDITIONS) as TermsAndConditions,
    ];

    elements.forEach((element) => {
      if (element) {
        element.style.display = show ? "" : "none";
      }
    });
  }

  async connectedCallback() {
    this.firstRender = false;
    await this.mountComponent();

  }


  async attributeChangedCallback(name, oldValue, newValue) {
    if (this.firstRender && oldValue === null)
      return;

    if (name === SESSION_ID && oldValue !== newValue) {
      await this.mountComponent();
    }
  }

  async mountComponent() {
    this.sessionId = this.getAttribute(SESSION_ID);
    this.brand = this.getAttribute(PG_BRAND);
    this.culture = this.getAttribute(PG_CULTURE);
    this.apiBaseUrl = this.getAttribute(API_BASE_ADDRESS);
    this.isTestEnvironment = this.getAttribute(IS_TEST_ENVIRONMENT) === "true";
    this.hostContainerId = HOST_CONTAINER + "-" + this.sessionId;

    const html = <div id={this.hostContainerId}></div>;
    this.innerHTML = await renderToString(html);

    let environment: typeof LIVE | typeof TEST = TEST;
    if (!this.isTestEnvironment) {
      environment = LIVE;
    }

    const spinnerProps = this.getAttribute("spinner-props");
    if (!!spinnerProps) {
      const spinner = document.createElement(SPINNER) as Spinner;
      document.getElementById(this.hostContainerId).hidden = true;
      spinner.id = FORM_LOADING_SPINNER;
      spinner.spinnerProps = {
        label: "Loading",
        size: "large",
        ...(JSON.parse(spinnerProps) as SpinnerProps),
      };
      document
        .getElementById(this.hostContainerId)
        .insertAdjacentElement("afterend", spinner);
    }

    const formSettings = await this.getFormSettings();
    const paymentMethod = formSettings.paymentMethods.find(
      (x) => x.providerSettings.providerType === PaymentProviderType.Adyen
    );
    const clientKey = (paymentMethod.providerSettings as AdyenProviderSettings)
      .clientKey;

    const adyenDropinConfiguration: AdyenDropinContainerConfiguration = {
      clientKey: clientKey,
      environment: environment,
      sessionId: this.sessionId,
      brand: this.brand,
      culture: this.culture,
      apiBaseUrl: this.apiBaseUrl,
      hostContainerId: this.hostContainerId,
      ...formSettings,
    };

    let hostContainer = document.getElementById(this.hostContainerId);
    hostContainer.replaceChildren(); // Clear down the host container

    if (formSettings.type === FormSettingsType.Payment) {
      const adyenDropinContainer = document.createElement(
        ADYEN_DROPIN_CONTAINER
      ) as AdyenDropinContainer;

      const paymentSummary = document.createElement(
        PAYMENTS_SUMMARY
      ) as PaymentsSummary;
      paymentSummary.formSettings = formSettings;
      hostContainer.appendChild(paymentSummary);

      const additionalValidations: AdditionalValidation[] = [];

      const legalTerms = document.createElement(LEGAL_TERMS_FOR_PAID_CLASS) as LegalTermsForPaidClass;
      legalTerms.formSettings = formSettings;
      hostContainer.appendChild(legalTerms);

      additionalValidations.push({
        elementIdToValidate: LEGAL_TERMS_CHECKBOX,
        validationType: AdditionalValidationType.Checked,
        validationElementGroup: LEGAL_TERMS_FOR_PAID_CLASS,
        validationElement: legalTerms,
      } as AdditionalValidation);

      if (additionalValidations.length > 0) {
        adyenDropinConfiguration.additionalValidations = additionalValidations;
      }

      adyenDropinContainer.configuration = adyenDropinConfiguration;
      hostContainer.appendChild(adyenDropinContainer);

      const termsAndConditions = document.createElement(TERMS_AND_CONDITIONS) as TermsAndConditions;
      hostContainer.appendChild(termsAndConditions);
    }

    let spinner = document.getElementById(BUNDLE_LOADING_SPINNER);
    if (!!spinner) {
      spinner.hidden = true;
    }
  }
}

if (!customElements.get(PAYMENTS_PAID_CLASS_WEB_COMPONENT)) {
  customElements.define(PAYMENTS_PAID_CLASS_WEB_COMPONENT, PaymentsPaidClassWebComponent);
}
