export const LOADING = "loading";
export const READY = "ready";
export const ERROR = "error";
export const SUCCESS = "success";
export const REFUSED = "Refused";
export const SCHEME = "Scheme";
export const GOOGLEPAY = "GooglePay";
export const INSTANT_PAYMENT_TYPES = ["applepay", "googlepay", "paywithgoogle"];
export const CUSTOM_PAY_BUTTON = "custom-pay-button";
export const GETPAYMETHODS_ENDPOINT = "/adyenprocessor/getpaymentmethods";
export const MAKEPAYMENT_ENDPOINT = "/adyenprocessor/makepayment";
export const THREEDS2_ENDPOINT = "/adyenprocessor/completethreeds2";
