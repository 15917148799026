import {
  BRAND_HEADER,
  CONTENT_TYPE_HEADER,
  CULTURE_HEADER,
  JSON_CONTENT_TYPE,
  PROBLEM_JSON_CONTENT_TYPE,
} from "../constants";
import ApiError from "../model/apiError";

interface RequestConfig {
  method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  url: string;
  headers?: Record<string, string>;
  body?: any;
  queryParams?: Record<string, string>;
  credentials?: RequestCredentials;
  timeout?: number;
  brand: string;
  culture: string;
}

export enum ApiResultType {
  Ok,
  ClientError,
  ServerError,
  NetworkError,
}

export const NETWORK_ERROR = <ApiError>{
  type: "network-error",
  title: "A network error has occurred",
  detail: "A network error has occurred",
  instance: "",
};

export const UNKNOWN_SERVER_ERROR = <ApiError>{
  type: "unknown-server-error",
};

class HttpRequest {
  static getDefaultHeaders(
    brand: string,
    culture: string
  ): Record<string, string> {
    return {
      [BRAND_HEADER]: brand,
      [CULTURE_HEADER]: culture,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }

  static async formatResponse(response: Response) {
    if (response.ok) {
      return await this.parseSuccessResponse(response);
    } else {
      return await this.parseErrorResponse(response);
    }
  }

  static async parseSuccessResponse(response: Response) {
    let data;

    const contentType = response.headers.get(CONTENT_TYPE_HEADER);
    if (contentType && contentType.includes(JSON_CONTENT_TYPE)) {
      data = await response.json();
    } else {
      data = await response.text();
    }

    return <const>{
      result: ApiResultType.Ok,
      status: response.status,
      data,
    };
  }

  static async parseErrorResponse(response: Response) {
    let error: ApiError;

    let resultType = this.getErrorResultType(response);

    const contentTypeHeader = response.headers.get(CONTENT_TYPE_HEADER);
    if (
      contentTypeHeader &&
      contentTypeHeader.includes(PROBLEM_JSON_CONTENT_TYPE)
    ) {
      error = await response.json();
    } else {
      error = {
        type: "unknown-server-error",
        title: "",
        instance: "",
        status: response.status,
        detail: await response.text(),
      };
    }

    if (!error?.detail) {
      // Even if this was a 400, we can't read the problem to get the localised message to display,
      // so we'll have to treat this as a 500.
      resultType = ApiResultType.ServerError;
      error = UNKNOWN_SERVER_ERROR;
    }

    return <const>{
      result: resultType,
      status: response.status,
      error,
    };
  }

  static getErrorResultType(response: Response) {
    return response.status >= 400 && response.status <= 499
      ? ApiResultType.ClientError
      : ApiResultType.ServerError;
  }

  static async request(config: RequestConfig): Promise<any> {
    const {
      method = "GET",
      url,
      headers = {},
      body = null,
      queryParams = {},
      credentials = "same-origin",
      timeout = 60000, // the ingress controller has a read timeout of 60 seconds
      brand,
      culture,
    } = config;

    const fullUrl = new URL(url);
    Object.entries(queryParams).forEach(([key, value]) => {
      fullUrl.searchParams.append(key, value);
    });

    const options: RequestInit = {
      method,
      headers: { ...this.getDefaultHeaders(brand, culture), ...headers },
      credentials,
    };

    if (body) {
      options.body = typeof body === "string" ? body : JSON.stringify(body);
    }

    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), timeout);

      options.signal = controller.signal;

      const response = await fetch(fullUrl.toString(), options);
      clearTimeout(timeoutId);

      return await this.formatResponse(response);
    } catch (error) {
      return <const>{
        result: ApiResultType.ServerError,

        // This won't be shown to the user as the On Error callback will be triggered
        error: { ...UNKNOWN_SERVER_ERROR, instance: fullUrl.toString() },
      };
    }
  }

  static get(config: Omit<RequestConfig, "method">): Promise<any> {
    return this.request({ ...config, method: "GET" });
  }

  static post(config: Omit<RequestConfig, "method">): Promise<any> {
    return this.request({ ...config, method: "POST" });
  }

  static put(config: Omit<RequestConfig, "method">): Promise<any> {
    return this.request({ ...config, method: "PUT" });
  }

  static patch(config: Omit<RequestConfig, "method">): Promise<any> {
    return this.request({ ...config, method: "PATCH" });
  }

  static delete(config: Omit<RequestConfig, "method">): Promise<any> {
    return this.request({ ...config, method: "DELETE" });
  }
}

export default HttpRequest;
