export const CONTENT_TYPE_HEADER = "Content-Type";
export const JSON_CONTENT_TYPE = "application/json";
export const PROBLEM_JSON_CONTENT_TYPE = "application/problem+json";
export const BRAND_HEADER = "X-PureBrand";
export const CULTURE_HEADER = "Accept-Language";
export const GET_METHOD = "GET";
export const POST_METHOD = "POST";
export const SESSION_ID = "session-id";
export const PG_BRAND = "pg-brand";
export const PG_CULTURE = "pg-culture";
export const API_BASE_ADDRESS = "api-base-address";
export const IS_TEST_ENVIRONMENT = "is-test-environment";
export const SESSION_ID_URI = "sessionId";
export const PG_BRAND_URI = "pgBrand";
export const PG_CULTURE_URI = "pgCulture";
export const API_BASE_ADDRESS_URI = "apiBaseAddress";
export const IS_TEST_ENVIRONMENT_URI = "isTestEnvironment";
export const TEST = "test";
export const LIVE = "live";
export const OMIT = "omit";
export const INCLUDE = "include";
export const PAYMENT_METHOD_SUBTYPE = "PaymentMethodSubType";
