import {renderToString} from "jsx-async-runtime";
import * as terms from "../constants/terms-and-conditions";
import {TERMS_AND_CONDITIONS_HEADER, TERMS_AND_CONDITIONS_PANEL} from "../constants/terms-and-conditions";
import {ARIA_EXPANDED, ARIA_HIDDEN, CLICK, SCROLL_BUTTON, TERMS_AND_CONDITIONS, TEXT_CONTENT} from "../constants";

export class TermsAndConditions extends HTMLElement {
  constructor() {
    super();
  }

  addListener() {
    document.getElementById(TERMS_AND_CONDITIONS_HEADER).addEventListener(CLICK, () => {
      this.togglePanel();
    });
  }

  removeListener() {
    document.getElementById(TERMS_AND_CONDITIONS_HEADER).removeEventListener(CLICK, () => {
      this.togglePanel();
    });
  }

  togglePanel() {
    const header =  document.getElementById(TERMS_AND_CONDITIONS_HEADER);
    const panel = document.getElementById(TERMS_AND_CONDITIONS_PANEL);

    if (header && panel) {
      const isExpanded = header.getAttribute(ARIA_EXPANDED) === 'true';

      header.setAttribute(ARIA_EXPANDED, isExpanded ? 'false' : 'true');
      panel.setAttribute(ARIA_HIDDEN, isExpanded ? 'true' : 'false');
    }
  }

  async connectedCallback() {
    const html = (
      <div id={TERMS_AND_CONDITIONS} class="terms-and-conditions">
        <div class="terms-and-conditions-item">
          <button id={TERMS_AND_CONDITIONS_HEADER} class="terms-and-conditions-header" aria-expanded="false">
            {terms.termsAndConditionsTitle}
          </button>
          <div  id={TERMS_AND_CONDITIONS_PANEL} class="terms-and-conditions-panel" aria-hidden="true">
            {`<${SCROLL_BUTTON} ${TEXT_CONTENT}="Scroll to the top"></${SCROLL_BUTTON}>`}
            <div>
                <p class="terms-and-conditions-item-paragraph">{terms.premiumClassesTerms.openingStatement}</p>
                <p class="terms-and-conditions-item-paragraph">{terms.premiumClassesTerms.ageRestriction}</p>
                <ol>
                  {terms.premiumClassesTerms.mainTerms.map((term) => `<li>${term}</li>`).join('')}
                </ol>
                <p class="terms-and-conditions-item-paragraph">{terms.premiumClassesTerms.paymentTermsTitle}</p>
                <ol>
                  {terms.premiumClassesTerms.paymentTerms.map((term) => `<li>${term}</li>`).join('')}
                </ol>
                <p class="terms-and-conditions-item-paragraph">{terms.premiumClassesTerms.nonRefundablePolicyTitle}</p>
                <ol>
                  {terms.premiumClassesTerms.nonRefundablePolicyTerms.map((term) => `<li>${term}</li>`).join('')}
                </ol>
                <p class="terms-and-conditions-item-paragraph">{terms.premiumClassesTerms.cancellationPolicyTitle}</p>
                <p class="terms-and-conditions-item-paragraph">{terms.premiumClassesTerms.ifYouCancelTitle}</p>
                <ul>
                  {terms.premiumClassesTerms.ifYouCancelTerms.map((term) => `<li>${term}</li>`).join('')}
                </ul>
                <p class="terms-and-conditions-item-paragraph">{terms.premiumClassesTerms.ifPureGymCancelsTitle}</p>
                <ul>
                  {terms.premiumClassesTerms.ifPureGymCancelsTerms.map((term) => `<li>${term}</li>`).join('')}
                </ul>
                <p class="terms-and-conditions-item-paragraph">{terms.premiumClassesTerms.bookingRightsTitle}</p>
                <ol>
                  {terms.premiumClassesTerms.bookingRightsTerms.map((term) => `<li>${term}</li>`).join('')}
                </ol>
              </div>
          </div>
        </div>
      </div>
    );

    this.innerHTML = await renderToString(html);
    this.addListener();
  }

  async disconnectedCallback() {
    this.removeListener();
  }
}

if (!customElements.get(TERMS_AND_CONDITIONS)) {
  customElements.define(TERMS_AND_CONDITIONS, TermsAndConditions);
}
