import { renderToString } from "jsx-async-runtime";
import PaymentSettings from "../model/payment/PaymentSettings";
import { CLICK, LEGAL_TERMS } from "../constants";
import { AdditionalValidationElement } from "../model/validation/AdditionalValidationElement";

export class LegalTerms extends HTMLElement implements AdditionalValidationElement {
    private _formSettings: PaymentSettings;

    constructor() {
        super();
    }
    showElement(): void {
        this.style.display = "";
    }
    hideElement(): void {
        this.style.display = "none";
    }
    validateElement(): boolean {
        const checkBox = this.querySelector("#product-legal-terms") as HTMLInputElement;
        return checkBox.checked;
    }

    get formSettings(): PaymentSettings {
        return this._formSettings;
    }

    set formSettings(value: PaymentSettings) {
        this._formSettings = value;
    }

    addCleanupListener() {
        document.getElementById(LEGAL_TERMS).addEventListener(CLICK, 
            this.clickHandler());
    }

    removeCleanupListener() {
        document.getElementById(LEGAL_TERMS).removeEventListener(CLICK,
            this.clickHandler());
    }

    clickHandler = () => () => {
        let host = document.getElementById(LEGAL_TERMS);
        host.className = "";
    }

    async connectedCallback() {
        const formSettingsData = this.formSettings;

        const html = (            
            <div id={LEGAL_TERMS}>
                <label class="legal-terms-checkbox-container" data-testid="ProductLegalTermsLabel">{formSettingsData.resources.legalTerms.statement}
                    <span class="legal-terms-required">*</span>
                    <input id="product-legal-terms" type="checkbox" name="ProductLegalTerms" />
                    <span class="legal-terms-link">
                        <a data-testid="ProductLegalTermsLink" href={formSettingsData.resources.legalTerms.linkUrl} target="_blank">{formSettingsData.resources.legalTerms.linkText}</a>
                    </span>
                    <span id="legal-terms-checkmark" data-testid="ProductLegalTermsCheckbox" class="legal-terms-checkmark"></span>
                </label>
                <p data-testid="ProductLegalTermsError" id="product-legal-terms-error" class="legal-terms-error" hidden="true">
                    {formSettingsData.resources.legalTerms.notAgreedError}
                </p>
            </div>
        );

        this.innerHTML = await renderToString(html);
        this.addCleanupListener();
    }

    async disconnectedCallback() {
        this.removeCleanupListener();
    }
}

if (!customElements.get(LEGAL_TERMS)) {
    customElements.define(LEGAL_TERMS, LegalTerms);
}
